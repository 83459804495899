<script setup lang="ts">
import type { ContentMediaImage } from '~/types'
import type UiImg from '~/components/ui/UiImg.vue'

defineProps<{
  media: ContentMediaImage
}>()

const img = ref<InstanceType<typeof UiImg>>()
const allowUpscale = ref(false)
const openViewer = ref(false)

onMounted(() => {
  setAllowUpscale()
  addEventListener('resize', setAllowUpscale)
  onBeforeUnmount(() => {
    removeEventListener('resize', setAllowUpscale)
  })
})

function setAllowUpscale() {
  allowUpscale.value = makeSenseToUpscale()
}
function makeSenseToUpscale() {
  if (!img.value?.el) {
    return false
  }
  const minFact = 1.2
  const { width, height } = img.value.el.getBoundingClientRect()
  const { innerWidth, innerHeight } = window
  if (width * minFact <= innerWidth || height * minFact <= innerHeight) {
    return true
  }
  return false
}
</script>

<template>
  <UiImg
    ref="img"
    :src="media.src"
    :aspect-ratio="media.aspect ?? 1"
    class="full-width-content"
    @click="openViewer = true"
  />
  <ContentImageViewerPop
    v-if="allowUpscale"
    :open="openViewer"
    :src="media.src"
    :aspect-ratio="media.aspect ?? 1"
    @request-close="openViewer = false"
  />
</template>

<style lang='scss'>
.content-image {
  position: relative;
}
</style>
