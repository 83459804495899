import { LRUCache } from 'lru-cache'
import type { mastodon } from '#types'

const cache = new LRUCache<string, any>({
  max: 1000,
})

export async function fetchStatus(id: string | undefined, force = false): Promise<mastodon.v1.Status | null> {
  const key = `status:${id}`
  const cached: Promise<mastodon.v1.Status> = cache.get(key)
  if (cached && !force) {
    const status = await Promise.resolve(cached)
    if (status.tags.length === 0) {
      status.tags = await $fetch<mastodon.v1.Tag[]>(`/api/statuses/${id}/tags`)
    }
    cacheStatus(status)
    return status
  }
  const promise = $fetch<mastodon.v1.Status>(`/api/statuses/${id}`).then((status) => {
    cacheStatus(status)
    return status
  })
  cache.set(key, promise)
  return promise
}

export function cacheStatus(status: mastodon.v1.Status, override?: boolean) {
  for (const tag of status.tags) {
    cacheTag(tag)
  }
  setCached(`status:${status.id}`, status, override)
}

export function removeCachedStatus(id: string) {
  cache.delete(`status:${id}`)
}

export function fetchTag(tagName: string, force = false): Promise<mastodon.v1.Tag> {
  const key = `tag:${tagName}`
  const cached = cache.get(key)
  if (cached && !force) {
    return Promise.resolve(cached)
  }
  const promise = $fetch<mastodon.v1.Tag>(`/api/tags/${tagName}`).then((tag) => {
    cacheTag(tag)
    return tag
  })
  cache.set(key, promise)
  return promise
}

export function cacheTag(tag: mastodon.v1.Tag, override?: boolean) {
  setCached(`tag:${tag.name}`, tag, override)
}

export function fetchAccountByHandle(acct: string): Promise<mastodon.v1.Account | null> {
  const domain = useRuntimeConfig().public.domain
  const userAcct = acct.endsWith(`@${domain}`) ? acct.slice(0, -domain.length - 1) : acct
  const userId = useAuth().account.value?.id
  const key = `${userId}:account:${userAcct}`
  const cached = cache.get(key)
  if (cached) {
    return Promise.resolve(cached)
  }

  const promise = $fetch<mastodon.v1.Account>('/api/accounts/lookup', {
    query: { acct: userAcct },
  }).then((data) => {
    if (data.acct && !data.acct.includes('@')) {
      data.acct = `${data.acct}@${domain}`
    }
    setCached(key, data, true)
    return data
  })
  cache.set(key, promise)
  return promise
}

export function cacheAccount(account: mastodon.v1.Account, override?: boolean) {
  const domain = useRuntimeConfig().public.domain
  const userId = useAuth().account.value?.id
  const userAcct = account.acct.endsWith(`@${domain}`) ? account.acct.slice(0, -domain.length - 1) : account.acct
  setCached(`${userId}:account:${account.id}`, account, override)
  setCached(`${userId}:account:${userAcct}`, account, override)
}

function setCached(key: string, value: any, override = false) {
  if (override || !cache.has(key)) {
    cache.set(key, value)
  }
}
