<script setup lang="ts">
import type { ContentMedia } from '~/types/content-media'

const props = defineProps<{
  mediaItems: ContentMedia[]
  type?: string
  publishId?: string
}>()

const isMultipleMediaItems = computed(() => props.mediaItems.length > 1)

const isSlideshow = computed(() => props.type === 'slideshow' && props.mediaItems.length > 1)

const currentIndex = ref(0)

const slideshowVars = computed(() => {
  if (isSlideshow.value) {
    return {
      '--slide-index': `${currentIndex.value}`,
      '--num-slides': `${props.mediaItems.length}`,
    }
  }
})

const gridVars = computed(() => {
  return gridAspect.value && !isSlideshow.value ? { 'aspect-ratio': gridAspect.value } : undefined
})

const gridAspect = computed(() => {
  return props.mediaItems.reduce<number | undefined>((res, item) => {
    if ((item.type === 'image' || item.type === 'video') && item.aspect) {
      return res ? Math.min(item.aspect, res) : item.aspect
    }
    return res
  }, undefined)
})

function gotoNextSlide() {
  if (!isMultipleMediaItems.value) { return }
  currentIndex.value = (currentIndex.value + 1) % props.mediaItems.length
}

function gotoPreviousSlide() {
  if (!isMultipleMediaItems.value) { return }
  currentIndex.value = (currentIndex.value - 1 < 0) ? props.mediaItems.length - 1 : currentIndex.value - 1
}
</script>

<template>
  <div v-if="mediaItems.length" class="media-viewer-box full-width-content" :class="{ 'slideshow-box': isSlideshow }">
    <ul class="media" :class="[type]" :style="{ ...slideshowVars, ...gridVars }">
      <li v-for="media in mediaItems" :key="media.id">
        <figure>
          <ContentImage v-if="media.type === 'image'" :media="media" />
          <ContentVideoPlayer v-else-if="media.type === 'video'" :media="media" :publish-id="publishId" />
          <ContentAudioPlayer v-else-if="media.type === 'audio'" :media="media" :publish-id="publishId" />
          <figcaption>{{ '' }}</figcaption>
        </figure>
        <slot name="media-item-overlay" :media-item="media" />
      </li>
    </ul>
    <footer v-if="isSlideshow">
      <div class="right">
        <UiBar>
          <button v-if="isMultipleMediaItems" class="compact" @click="gotoPreviousSlide">
            <span class="icon-chevron-left" />
          </button>
          <button v-if="isMultipleMediaItems" class="compact" @click="gotoNextSlide">
            <span class="icon-chevron-right" />
          </button>
        </UiBar>
        <slot name="footer-right" />
      </div>
    </footer>
  </div>
</template>

<style lang='scss'>
@use '@/styles/mixins';

.media-viewer-box {
  container-type: inline-size;
  position: relative;
  overflow: hidden; // backward compatibility (children won't stick)
  overflow: clip; // "clip" allows children with sticky position work properly

  ul {
    list-style: none;

    li {
      margin-inline-start: 0;
    }
  }

  footer {
    display: flex;
    align-items: flex-end;
    z-index: 200;
    padding: var(--padding-mini) var(--padding-mini) 0 0;
    position: sticky;
    bottom: 0;
    height: var(--media-viewer-footer-h);

    &.grid-footer {
      right: 0;
      z-index: 200;
    }

    &>* {
      pointer-events: auto;
    }

    .right {
      margin-left: auto;
    }

    .expandable-caption {
      position: relative;
      padding-block: var(--padding-small);
    }
  }
}

.media {
  --slides-gap: var(--padding-mini);
  position: relative;
  gap: var(--padding-small);
  width: 100%;
  height: fit-content;
  grid-gap: var(--slides-gap);

  &.grid {
    display: grid;
    grid-auto-rows: auto;
    padding-inline: var(--padding-mini);
    gap: var(--padding-mini);

    li {
      // aspect-ratio: 1 / 1;
    }

    figure,
    img {
      width: 100%;
      height: 100%;
    }
  }

  &.slideshow {
    --media-viewer-footer-h: var(--base-size);
    display: flex;
    align-items: center;
    transform: translateX(calc(var(--slide-index, 0) * (-100cqi - var(--slides-gap))));
    transition: transform 0.5s;

    li {
      width: 100%;
      flex-shrink: 0;
    }

    &::after {
      content: '';
      position: fixed;
      background: rgb(232, 232, 232);
      z-index: -1;
      height: 100%;
      width: calc(var(--num-slides, 1) * (100cqi + var(--slides-gap)));
    }
  }
}
</style>
