<script setup lang="ts">
import type { ContentMediaAudio } from '~/types'

const props = defineProps<{
  media: ContentMediaAudio
}>()

const canvas = ref<HTMLCanvasElement>()
const canvasClone = ref<HTMLCanvasElement>()
const { width: canvasWidth } = useElementSize(canvas)

const { $mediaUpload } = useNuxtApp()
const { drawStaticWave, cloneDrawingToCanvas } = useWaveformDrawing(canvas)

const uploading = computed(() => $mediaUpload?.isUploading(props.media.publishId))

const wave = computed(() => {
  if (props.media.wave) {
    return props.media.wave
  }
  if (props.media.id) {
    return $mediaUpload?.waveforms.get(props.media.id)?.wave ?? []
  }
  return []
})

drawStaticWave(wave, (n: number) => n)
cloneDrawingToCanvas(canvasClone)
</script>

<template>
  <ContentMediaPlayer type="audio" :src="media.src" :duration="media.duration" :class="{ uploading }">
    <template #timeline-background>
      <canvas ref="canvas" class="waveform" />
    </template>
    <template #progress-background>
      <canvas ref="canvasClone" :style="{ width: `${canvasWidth}px` }" />
    </template>
  </ContentMediaPlayer>
</template>

<style scoped>
canvas {
  height: var(--base-size);
  width: max(100%, var(--base-size) * 3);

  &.waveform {
    opacity: 0.4;
  }
}
</style>
