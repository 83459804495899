function pad00(n: number) {
  return String(n).padStart(2, '0')
}

export function secondsToTimecode(seconds: number) {
  const hours: number = Math.floor(seconds / 3600)
  const minutes: number = Math.floor((seconds % 3600) / 60)
  const finalSeconds: number = Math.floor(seconds % 60)
  const timecode = `${pad00(minutes)}:${pad00(finalSeconds)}`
  if (hours > 0) {
    return `${pad00(hours)}:${timecode}`
  }
  return timecode
}
