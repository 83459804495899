<script setup lang="ts">
defineProps<{
  open?: boolean
  src: string
  aspectRatio?: number | string
}>()
const emit = defineEmits(['request-close'])
</script>

<template>
  <UiPopoverWidget
    css-class="content-image-viewer-pop"
    :open="open"
    @request-close="emit('request-close')">
    <UiImg
      :src="src"
      :aspect-ratio="aspectRatio"
      @click="emit('request-close')"
    />
  </UiPopoverWidget>
</template>

<style lang='scss'>
.content-image-viewer-pop {
  --popover-width: 100%;
  --popover-height: 100%;

  .popover-modal {
    background-color: transparent;
    .popover-header {
      position: absolute;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
