<script setup lang="ts">
import { clickPositionX } from '~/lib/click-position'
import { secondsToTimecode } from '~/lib/seconds-to-timecode'

const props = defineProps<{
  type: 'audio' | 'video'
  src?: string
  duration?: number
}>()

const player = ref<HTMLAudioElement>()
const timelineBox = ref<HTMLElement>()
const timeline = ref<HTMLElement>()
const playhead = ref<HTMLElement>()
const playheadHandle = ref<HTMLElement>()
const { width: timelineWidth } = useElementSize(timeline)

const { currentTime, duration: durationMedia, playing, waiting, buffered } = useMediaControls(player, { src: props.src })

const duration = computed(() => {
  if (isFinite(durationMedia.value)) {
    return durationMedia.value
  }
  return props.duration ?? 0
})

const waitingMedia = computed(() => {
  return waiting.value || !buffered.value
})

useDraggable(playhead, {
  containerElement: timeline,
  handle: playheadHandle,
  axis: 'x',
  onMove: ({ x }) => {
    currentTime.value = x / timelineWidth.value * duration.value
  },
})

const playbackProgress = computed(() => !duration.value ? 0 : currentTime.value / duration.value * 100)

const playheadPosition = computed(() => {
  return currentTime.value / duration.value * timelineWidth.value
})

const durationStr = computed(() => secondsToTimecode(duration.value))
const currentTimeStr = computed(() => secondsToTimecode(currentTime.value))

watch(playing, (playing) => {
  if (playing) {
    playheadHandle.value?.focus()
  }
})

function togglePlaying() {
  playing.value = !playing.value
}

function onClickTimelineBox(e: MouseEvent) {
  if (e.target === playheadHandle.value) {
    return
  }
  const x = clamp(clickPositionX(e, timeline.value) ?? 0, 0, timelineWidth.value)
  currentTime.value = x / timelineWidth.value * duration.value
  if (!playing.value) {
    playing.value = true
  }
}

function focusPlayer() {
  player.value?.focus()
}

function onKeyDown(event: KeyboardEvent) {
  if (event.key === ' ') {
    togglePlaying()
  }
}

function clamp(n: number, min: number, max: number) {
  return Math.min(Math.max(n, min), max)
}
</script>

<template>
  <div :class="[`${type}-player`]" class="media-player" @click="focusPlayer">
    <Component :is="type" ref="player" :src="src" preload="metadata" class="player" />
    <div class="controls">
      <UiBar>
        <button class="compact" @click="togglePlaying">
          <span v-if="playing" class="icon-pause" />
          <span v-else class="icon-play" />
        </button>
      </UiBar>
      <UiBar class="timeline-wrap">
        <div ref="timelineBox" class="player-timeline" @click="onClickTimelineBox">
          <div ref="timeline" class="timeline-bar">
            <slot name="timeline-background">
              <div class="timeline-background" />
            </slot>
            <div class="progress" :style="{ width: `${playbackProgress}%` }">
              <slot name="progress-background">
                <div class="progress-background" />
              </slot>
            </div>
            <div ref="playhead" class="playhead" :style="{ left: `${playheadPosition}px` }">
              <button ref="playheadHandle" class="compact playhead-handle" @keydown="onKeyDown">
                <SpinnerIcon v-if="waitingMedia" />
              </button>
            </div>
          </div>
          <div class="time-monitor desc">
            {{ `${durationStr}${currentTime ? ' / ' + currentTimeStr : ''}` }}
          </div>
        </div>
      </UiBar>
    </div>
  </div>
</template>

<style lang='scss'>
.media-player {
  position: relative;
  display: grid;
  width: 100%;
  grid-template-areas:
    "player"
    "controls"
  ;

  .player {
    grid-row: player / controls;
    grid-column: 1;
    width: 100%;
    min-height: var(--base-size);
    background-color: #6b748a;
  }

  .controls {
    grid-row: controls;
    grid-column: 1;
    width: min(100% - var(--padding-base), var(--base-size) * 8);
    min-height: var(--button-height);
    display: grid;
    grid-template-columns: var(--button-height) 1fr;
    align-items: center;
    justify-self: center;
    margin-bottom: var(--padding-small);
  }

  &.audio-player {
    .controls {
      width: 100%;
      margin-bottom: 0;
    }
  }

  .timeline-wrap {
    width: 100%;
  }

  .player-timeline {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius-base);
    padding: var(--padding-medium) calc(var(--button-height) / 2) var(--padding-mini);

    .timeline-bar {
      position: relative;
      height: fit-content;
      max-height: calc(var(--base-size)* 1);
    }

    .progress {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      overflow: hidden;
    }

    .timeline-bar,
    .progress {
      &>* {

        &.timeline-background,
        &.progress-background {
          background-color: black;
          height: 2px;
          border-radius: 999px;
        }

        &.timeline-background {
          opacity: 0.3;
        }
      }
    }

    .playhead {
      position: absolute;
      width: 0;
      height: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .playhead-handle {
      position: absolute;
      width: var(--button-height);
      height: var(--button-height);
      transform: translate(0%, -50%);
      top: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      .spinner-wrap {
        --spinner-size: var(--padding-base);
        position: absolute;
      }

      &::after {
        content: '';
        display: block;
        width: var(--padding-base);
        height: var(--padding-base);
        background-color: rgb(162, 162, 162);
        border-radius: 50%;
        opacity: 1;
      }

      &:focus::after {
        background-color: rgb(0, 0, 0);
        opacity: 1;
      }

    }

    .time-monitor {
      margin-top: var(--padding-small);
    }
  }

  &.audio-player {
    .player-timeline .time-monitor {
      margin-top: var(--padding-base);
    }
  }

  &:focus-within {
    opacity: 1;

    .playhead-handle::after {
      background-color: black;
    }
  }
}
</style>
