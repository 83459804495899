<script setup lang="ts">
const props = defineProps<{
  padding?: boolean
  corner?: boolean
  text?: boolean
  bg?: boolean
}>()
const classList = computed(() => {
  const classes = Object.entries(props).filter(([, v]) => !!v).map(([k]) => `ui-bar-${k}`)
  if (!props.bg) { classes.push('ui-bar-panel') }
  return classes
})
</script>
<template>
  <div class="ui-bar" :class="classList">
    <slot />
  </div>
</template>

<style lang='scss'>
.ui-bar {
  --bar-opacity: var(--ui-opacity, 50%);
  --p: 0px;
  --c: var(--ui-color-bg, var(--color-panel));
  --r: var(--border-radius-base);
  --bg: color-mix(in lab, var(--c) var(--bar-opacity), transparent);
  display: flex;
  align-items: center;
  gap: var(--padding-mini);
  padding: var(--p);
  border-radius: calc(var(--r) + var(--p));
  overflow: clip;
  backdrop-filter: blur(var(--ui-filter-blur, 15px));
  background-color: var(--bg);
  transition: background-color 0.2s;
  width: fit-content;

  &-padding {
    --p: var(--padding-mini);
  }

  &-corner {
    --r: var(--corner-radius);
  }

  &-text {
    --p: var(--padding-mini) var(--padding-small);
    border-radius: var(--r);
  }

  &-bg {
    --c: var(--color-bg);
  }

  &-panel {
    --c: var(--color-panel);
    --color-text: var(--color-panel-text);
  }
}
</style>
