<script setup lang="ts">
import type { ContentMediaVideo } from '~/types'

const props = defineProps<{
  media: ContentMediaVideo
}>()
const { $mediaUpload } = useNuxtApp()
const uploading = computed(() => $mediaUpload?.isUploading(props.media.publishId))
</script>

<template>
  <ContentMediaPlayer type="video" :src="media.src" :duration="media.duration" class="full-width-content" :class="{ uploading }" />
</template>
